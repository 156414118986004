<style scoped>
#container {
  height: 100vh;
}
.diagonal-box {
  position: relative;
  background-image: linear-gradient(210deg, #2bd9eb 50%, #043561 50%);
}
h1, p {
  word-break: break-word;
}
</style>
<template>
  <div id="container" class="diagonal-box d-flex">
    <div v-if="$vuetify.breakpoint.mdAndUp" class="flex-grow-1 d-flex align-center justify-center">
      <v-card flat class="transparent white--text" max-width="600">
        <v-card-title>
          <h1 class="text-h3">
            <b>Telescope</b> Connects
            <br> <u>Attorneys</u> With
            <br> <u>Healthcare Clinics</u>.
          </h1>
        </v-card-title>
        <v-card-text class="white--text text-subtitle-1">
          <ul>
            <li>INSTANT UPDATES on patient progress</li>
            <li>Up to date patient BILLING ESTIMATES</li>
            <li>Easy DOCUMENT MANAGEMENT and e-signatures</li>
          </ul>
        </v-card-text>
      </v-card>
    </div>

    <div class="flex-grow-1 d-flex align-center justify-center">
      <v-card class="px-2 py-6 pa-md-12" max-width="675">
        <v-card-title>
          <h1 class="text-h5" v-if="$route.query.token">You have been invited to <span class="font-weight-bold">Telescope</span></h1>
          <p class="text-h3" v-else-if="organization.type === null">What kind of organization do you want to create?</p>
          <p class="text-h3" v-else-if="organization.type === OrganizationTypes.LAW || organization.type === OrganizationTypes.CLINIC">Who will be managing your <b>{{ organization.type === OrganizationTypes.LAW ? 'Law Firm' : 'Health Clinic' }}?</b></p>
          <p class="text-h3" v-else>Enter information for your <b>Individual Account</b></p>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="formValid">

            <template v-if="!$route.query.token && organization.type === null">
              <div class="d-flex">
                <v-card class="d-flex justify-center" @click="organization.type = OrganizationTypes.CLINIC" height="100">
                  <v-card-title>A Health Clinic</v-card-title>
                </v-card>
                <div style="width: 15px"></div>
                <v-card class="d-flex justify-center" @click="organization.type = OrganizationTypes.LAW">
                  <v-card-title>A Law Firm</v-card-title>
                </v-card>
                <div style="width: 15px"></div>
                <v-card class="d-flex justify-center" @click="organization.type = OrganizationTypes.INDIVIDUAL">
                  <v-card-title>I am an Individual</v-card-title>
                </v-card>
              </div>
            </template>

            <template v-if="$route.query.token || organization.type !== null">
              <v-btn v-if="!$route.query.token" text @click="clearAll" class="mb-4">
                <v-icon small left>fa-arrow-left</v-icon>
                Back
              </v-btn>

              <v-text-field v-if="!$route.query.token && (organization.type === OrganizationTypes.LAW || organization.type === OrganizationTypes.CLINIC)"
                            v-model="organization.name"
                            filled dense
                            :rules="[rules.required]"
                            :label="organization.type === OrganizationTypes.LAW ? 'Name of Law Office' : 'Name of Health Clinic'" />

              <v-text-field
                v-if="organization.type !== OrganizationTypes.INDIVIDUAL"
                v-model="organization.user.name"
                filled dense :rules="[rules.required]"
                label="Your Full Name" />
              <v-text-field
                v-if="organization.type === OrganizationTypes.INDIVIDUAL"
                v-model="organization.patient.first_name"
                filled dense :rules="[rules.required]"
                label="Your First Name" />
              <v-text-field
                v-if="organization.type === OrganizationTypes.INDIVIDUAL"
                v-model="organization.patient.last_name"
                filled dense :rules="[rules.required]"
                label="Your Last Name" />
              <DatePicker
                v-if="organization.type === OrganizationTypes.INDIVIDUAL"
                ref="datepicker"
                :date="organization.patient.dob"
                :default-to-today="false"
                label="Date of Birth"
                @date-change="setDob"
                :dateValid="dobValid"
              />
              <v-text-field v-model="organization.user.email"
                            filled dense :rules="[rules.required, rules.email]"
                            label="Your Email Address" />
              <v-text-field v-model="organization.user.password"
                            filled dense :rules="[rules.required, rules.min]"
                            label="Password"
                            type="password"
                            hint="minimum 8 characters"
                            persistent-hint />
            </template>
          </v-form>
        </v-card-text>
        <v-card-actions class="flex-column">
          <v-btn v-if="organization.type === OrganizationTypes.CLINIC || organization.type === OrganizationTypes.LAW || $route.query.token" :loading="loading" block color="primary" @click="registerOrganization">create account</v-btn>
          <v-btn v-if="organization.type === OrganizationTypes.INDIVIDUAL" :loading="loading" block color="primary" @click="registerIndividual">create account</v-btn>
          <div class="align-self-start mt-2">
            Already have an Account? <v-btn text color="accent" :to="{ name: 'Login', query: {token: $route.query.token} }">Sign In</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>
<script>
import event, { Events } from '../event'
import RulesMixin from '../mixins/RulesMixin'
import { OrganizationTypes } from '@/js/OrganizationTypes'
import DatePicker from '@/components/PatientIntake/DatePicker'

export default {
  name: 'Register',
  mixins: [RulesMixin],
  components: { DatePicker },
  data: () => ({
    OrganizationTypes,
    formValid: true,
    loading: false,
    dobValid: true,
    organization: {
      type: null,
      name: '',
      user: {
        name: '',
        email: '',
        password: ''
      },
      patient: {
        first_name: '',
        last_name: '',
        dob: ''
      }
    },
    rules: {
      min: value => value.length >= 8 || 'must be minimum 8 characters'
    }
  }),
  methods: {
    register (url, params) {
      this.$refs.form.validate()
      if (this.formValid === false) {
        return
      }

      this.loading = true
      this.axios
        .post(url, params)
        .then(async () => {
          // after successful registration, log the user in
          await this
            .$store
            .dispatch('User/login', { email: this.organization.user.email, password: this.organization.user.password })

          event.emit(Events.SUCCESS, 'New account successfully created')
          await this.$router.push({ name: 'Dashboard' })
        })
        .finally(() => {
          this.loading = false
        })
    },
    registerOrganization () {
      const params = this.organization
      let url = '/v1/organization/create'

      if (this.$route.query.token) {
        params.token = this.$route.query.token
        url = '/v1/organization/user'
      }

      this.register(url, params)
    },
    registerIndividual () {
      const params = this.organization
      const url = '/v1/individual/create'

      // if (this.$route.query.token) {
      //   params.token = this.$route.query.token
      //   url = '/v1/individual/user'
      // }
      this.organization.user.name = [this.organization.patient.first_name, this.organization.patient.last_name].join(' ')
      this.validateDob()
      this.register(url, params)
    },
    setDob (value) {
      this.organization.patient.dob = value
    },
    validateDob () {
      this.dobValid = true
      if (!this.organization.patient.dob) {
        this.dobValid = 'Required'
      }
    },
    clearAll () {
      this.organization = {
        type: null,
        name: '',
        user: {
          name: '',
          email: '',
          password: ''
        },
        patient: {
          first_name: '',
          last_name: '',
          dob: ''
        }
      }
    }
  }
}
</script>
